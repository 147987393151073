.foodcourt-list-header {
  top: 6vh;
  left: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 10vh;
  background-color: white;
  color: white;
  padding-right: 5%;
  padding-left: 5%;
}

.foodcourt-list-content {
  position: relative;
  z-index: 1;
  top: 10vh;
  height: 100%;
}

