.round {
  background-color: #FE616B;
  color: #FFF;
  height: 6vh;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  box-sizing: border-box;
  border-radius: 1.2vh;
}

.no-color {
  background-color: transparent;
  color: black;
}

@media (min-width: 600px) {
  .round {
    font-size: inherit;
  }
}