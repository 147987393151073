.tab-center a {
  color: black;
}

.tab-cost {
  background-color: #8A4537;
  height: 5vh;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 4vw;
}

.app-center-barcode {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}