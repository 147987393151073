.section-about {
  margin: 5vh 0;
}

.about-header {
  font-size: 3vh;
  text-align: center;
  text-decoration: underline;
}

.section-about-text {
  width: 100%;
  height: 60vh;
  background-color: black;
  color: white;
  padding: 3vh 0;
}

.section-about-text p{
  font-weight: bold;
  font-size: 1.8vh;
  margin-left: 8%;
  margin-right: 8%;
  text-align: justify;
}


div.gallery-about img {
  height: 27vh;
  border-radius: 3vw;
  margin: auto;    
  display: block;
}

@media (min-width: 600px) {
  .section-about-text {
    height: 40vh;
  }

  .section-about-text p{
    font-size: 2vh;
  }

  .about-header {
    font-size: 3.5vh;
  }

  div.gallery-about img {
    height: 40vh;
  }
}

@media (min-width: 1200px) {
  .section-about-text {
    height: 30vh;
  }

  .section-about-text p{
    font-weight: bold;
    font-size: 2vh;
  }

  .about-header {
    font-size: 4vh;
  }

  div.gallery-about img {
    height: 60vh;
  }
}
