.complete-preorder-img {
  width: 100%;
  height: 50%;
  margin-bottom: 20%;
}

.complete-preorder-w {
  width: 75%;
}

@media (min-width: 600px) {
  .complete-preorder-w {
    width: 30%;
  }
}

