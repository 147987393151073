.Footer {
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
}

.Footer nav {
  height: 100%;
}