.wallet-box {
  height: 20vh;
  width: 100%;
}

.wallet-info-box {
  height: inherit;
}

.img-wallet {
  position: absolute;
  bottom: 0;
}

.img-wallet img {
  width: 100%;
  height: 10vh;
}

@media (min-width: 600px) {
  .img-wallet img  {
    height: 15vh;
  }

  .wallet-box {
    height: 40vh;
  }
}

@media (min-width: 900px) {  
  .img-wallet img  {
    height: 28vh;
  }
}