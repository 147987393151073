.section button {
  margin: 0 1vw;
}

/* div.gallery img {
  width: 100%;
  height: auto;
} */

div.gallery img {
  width: 100%;
  height: 27vh;
  border-radius: 3vw;
}

.responsive {
  /* trisakti */
  background-color:#FFD000;
  border-radius: 3vw;
  width: 100%;
}

.responsive-transparent {
  border-radius: 3vw;
  width: 100%;
}

.paddingcolumns{
  padding: 2%;
}

.marginsections{
  margin-top: 2vh;
}

.authsections {
  font-size: 1.5vh;
}

.google-img {
  margin-right: 1vw;
  width: 4vw;
}

.button-login {
  width: 15vw;
  height: 5vh;
  border-radius: 3vh 3vh 3vh 3vh;
  background-color: #FFD000;
  color: white;
}

@media (min-width: 600px) {
  .button-login {
    width: 15vw;
    height: 7vh;
    border-radius: 5vh 5vh 5vh 5vh;
  }

  .authsections {
    font-size: inherit;
  }

  .google-img {
    margin-right: 1vw;
    width: 1.5vw;
  }

  div.gallery img {
    width: 100%;
    height: 40vh;
  }
}

@media (min-width: 900px) {
  div.gallery img {
    width: 100%;
    height: 60vh;
  }
}