.carousel-control-prev-icon  {
  background-image: none;
}

.carousel-control-next-icon {
  background-image: none;
}

.carousel-indicators{
  margin-bottom: 1vh;
}

.carousel-control-next, .carousel-control-prev {
  opacity: 0;
  filter:alpha(opacity=0);
}

.carousel-indicators li {
  width: 1vh;
  height: 1vh;
  margin: 0.5vh;
  border-radius: 100%;
  background-color: white;
  color: white;
  opacity: 100;
}

.carousel-indicators .active {
  width: 1vh;
  height: 1vh;
  margin: 0.5vh;
  background-color:#FFB000;
}

.vertical-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1vh;
}

.vertical-center h3 {
  letter-spacing: 0.1vh;
  font-weight: bold;
  font-size: 2vh;
  margin-bottom: 0;
}

.inner {
  height: 20vh;
  width: 100%;
  background-color: black;
}

.carousel-inner {
  border-radius: 3vh;
  height: 20vh;
  background-color: transparent;
}

@media (min-width: 600px) {
  .carousel-inner {
    height: 25vh;
    background-color: black;
  }

  .inner {
    height: 25vh;
  }

  .vertical-center {
    font-size: 1.5vh;
  }
  
  .vertical-center h3 {
    font-size: 2.5vh;
  }
}

@media (min-width: 1200px) {
  .carousel-inner {
    height: 30vh;
    background-color: black;
  }

  .inner {
    height: 30vh;
  }

  .vertical-center {
    font-size: 2vh;
  }
  
  .vertical-center h3 {
    font-size: 3vh;
  }
}

