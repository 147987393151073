.nav-tabs .nav-link {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.nav-tabs {
  border-bottom: 1px solid #A2A2A2;
}

.nav-tabs .nav-link.active {
  border-color: #A2A2A2 #A2A2A2 transparent #A2A2A2;
}

.card-title {
  margin-bottom: 0;
}

.preorder-merchant-header {
  top: 10vh;
  left: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: white;
  color: white;
  padding-top: 5vh;
  padding-left: 5%;
  padding-right: 5%;
}

.preorder-merchant-content {
  position: relative;
  z-index: 1;
  top: 10vh;
  height: 100%;
}

.tab-head {
  position: fixed;
  top: 6vh;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
}

.tab-content {
  position: relative;
  top: 2vh;
  width: 100%;
  z-index: 1;
}

.card-body{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.img-preorder {
  height: 50vh;
}

.Input-BS {
  font-family: inherit;
  font-size: inherit;
}

.margin-preorderlist {
  margin-top: 18vh;
  margin-bottom: 2vh;
}

.preorder-description {
  width: 65%;
}

/* .truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate + a {
display: inline-block;
  vertical-align: top;
  margin-top: -1px;
} */

/* @media (min-width: 600px) {
  .img-preorder {
    height: 60vh;
  }
} */
