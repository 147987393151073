.button-counter-plus-minus-cart {
  position: relative;
  width: 70%;
  height: 40px;
  padding: 0;
}

.button-counter-plus-minus-cart .dec.qty {
  top: 0;
  left: 0;
  height: 40px;
}

.button-counter-plus-minus-cart .inc.qty {
  top: 0;
  right: 0;
  height: 40px;
}

.button-counter-plus-minus-cart .inc.qty  {
  font-weight: bold;
  position: absolute;
  float: inherit;
  width: 30px;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-align: center;
  color: black;
  border: none;
  background: none;
}

.button-counter-plus-minus-cart .dec.qty {
  font-weight: bold;
  position: absolute;
  float: inherit;
  width: 30px;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-align: center;
  color: black;
  border: none;
  background: none;
}

.button-counter-plus-minus-cart input.plus-minus-box {
  font-weight: bold;
  float: left;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: black;
  border-color: gray;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #aaaaaa;
  border-style: groove;
  background: transparent none repeat scroll 0 0;
}
