/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'TitilliumWeb-Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  margin: 0;
  font-family: 'TitilliumWeb-Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  margin: 1vh 0;
}

button:focus {outline:0;}

@font-face {
  font-family: 'TitilliumWeb-Bold';
  src: local('TitilliumWeb-Bold'), url(./assets/fonts/TitilliumWeb-Bold.ttf) format('woff');
}

.tab-center {
  justify-content: center;
}

form {
  margin: 2vh 0;
}

.error-message {
  font-size: 2vw;
  color: red
}

.text-middle {
  text-align: center;
}

.app-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-fixed-center {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

h6 {
  text-align: center;
  font-family: 'TitilliumWeb-Bold';
  color: black;
  font-size: 1rem;
}

.warning {
  font-size: 0.6rem;
  color: red;
}

.success {
  font-size: 0.6rem;
  color:green;
}

.listbody{
  margin: 0 6vw;
}

.listbodyVCenter{
  height: 100%;
  margin: auto 4vw;
}

.push-right {
  margin-right: 2vw;
}

.push-left {
  margin-left: 2vw;
}

.push-up {
  margin-top: 1vh;
}

.push-up-md {
  margin-top: 3vh;
}

.push-up-lg {
  margin-top: 5vh;
}

.push-bottom {
  margin-bottom: 1vh;
}

.push-bottom-padd {
  padding-bottom: 1vh;
}

.push-bottom-md {
  margin-bottom: 3vh;
}

.push-bottom-lg {
  margin-bottom: 5vh;
}

.margin-upper {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.card-no-border {
  border: none;
  margin: 2vh 0;
  padding: 10px 5px;
}

.information {
  font-size: 1rem;
  color: black;
}

.information-small {
  font-size: 0.8rem;
  color: black;
}

.information-header {
  font-size: 1.2rem;
  color: black;
}

.padding-bottom-checkout {
  padding-bottom: 20vh;
}

.cart-box {
  width: 100%;
  background-color: white;
}

.cart-box-info {
  width: 70%;
}

.RoundedButton {
  width: 100%;
}

/* .list img {
  width: 100%;
  height: 16vh;
  border: 1px solid #A2A2A2;
  border-radius: 1vh 1vh 1vh 1vh;
} */

.wordwrap { 
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word;      /* IE */
}

.list img {
  width: 13vh;
  height: 13vh;
  border: 1px solid #A2A2A2;
  border-radius: 1vh 1vh 1vh 1vh;
}

.button-order {
  width: 3.2vh;
  height: 3.2vh;
  border-radius: 100%;
  background-color: #FE616B;
  color: #FFF;
  text-align: center;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 0.8rem;
  border: none;
}

.button-order-box {
  background-color: #FE616B;
  color: #FFF;
  border-radius: 30%;
  border: 2px solid; 
  width: 4vh;
  height: 4vh;
  text-align: center;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 0.8rem;
}

.v-align-center {
  margin: auto;
  padding-bottom: 2px;
}

.auth {
  margin: 0px auto;
  width: 80%;
  padding: 10px;
}

.auth-string {
  font-size: 2vw !important
}

.auth-header {
  font-size: 3vw !important
}

.auth-padding {
  margin: 2vh 0 !important
}

input[type="checkbox"]
{
    vertical-align:middle;
}

.front {
  z-index: 500;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.original-font {
  font-family: initial;
}

.no-margin {
  margin: 0;
}

span.inner {
  color: black;
  background-color: transparent;
}

span.outer {
  color: red;
  text-decoration: line-through;
}

.bg-area {
  position: fixed; 
  top: 0; 
  left: 0; 
  min-width: 100%;
  min-height: 100%;
  
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.area {
  position: fixed;
  z-index: 1;
}

.area-list {
  width: 100%;
  padding: 2vh;
  color: white;
  cursor:pointer;
}

.area-header-filter {
  width: 70%;
}

.area-header {
  top: 6vh;
  left: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color:#762B17;
  height: 30%;
  color: white;
}

.area-header-info {
  font-size: 0.8rem;
}

.area-header-info2 {
  font-size: 0.6rem;
}

.area-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -10%);
  transform: translate(-50%, -10%);
  overflow-y: scroll;
  width: 100%;
}

.page-link {
  color: black;
}

.page-item.active .page-link {
  color: black;
  background-color: #A2A2A2;
  border-color: transparent;
}

.pagination {
  font-size: 1rem;
}

@media (min-width: 600px) {
  /* .list img  {
    border-radius: 2vh 2vh 2vh 2vh;
    height: 21vh;
  } */

  .list img  {
    border-radius: 2vh 2vh 2vh 2vh;
    height: 15vh;
    width: 15vh;
  }

  .button-order {
    width: 4.2vh;
    height: 4.2vh; 
    font-size: 1.2rem;
  }

  .button-order-box {
    width: 5vh;
    height: 5vh; 
    font-size: 1.2rem;
  }
}

@media (min-width: 900px) {  
  .listbody{
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1.5rem;
  }

  .information {
    font-size: 1.4rem;
  }

  .information-small {
    font-size: 1.2rem;
  }

  .information-header {
    font-size: 1.6rem;
  }

  /* .list img  {
    border-radius: 3vh 3vh 3vh 3vh;
    height: 34vh;
  } */

  .area-header-info {
    font-size: 1.4rem;
  }
  
  .area-header-info2 {
    font-size: 1.2rem;
  }

  .list img  {
    border-radius: 3vh 3vh 3vh 3vh;
    height: 28vh;
    width: 28vh;
  }
}