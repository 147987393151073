.list-brands {
  margin: 0 2vw;
  display: inline;
}

.list-brands a{
  color: black
}

.section-icons {
  padding: 0 ;
}

.section-contact {
  margin: 5vh 0;
}

.contact-header {
  font-size: 3vh;
  text-align: center;
  text-decoration: underline;
}

.section-contact-text {
  width: 100%;
  color: black;
  padding: 3vh 0;
  text-align: center;
}

.section-contact-text p{
  font-weight: bold;
  font-size: 1.8vh;
  margin: 0.5vh 0;
  text-align: center;
}

@media (min-width: 600px) {
  /* .section-contact-text {
    height: 40vh;
  } */

  .section-contact-text p{
    font-size: 2vh;
  }

  .contact-header {
    font-size: 3.5vh;
  }
}

@media (min-width: 1200px) {
  /* .section-contact-text {
    height: 30vh;
  } */

  .section-contact-text p{
    font-weight: bold;
    font-size: 2vh;
  }

  .contact-header {
    font-size: 4vh;
  }
}
