.DrawerToggle {
  width: 5vh;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle div {
  width: 80%;
  height: 0.3vh;
  background-color: #A2A2A2;
}

.BottomTop {
  width: 60% !important;
  height: 0.3vh !important;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  float: right;
}