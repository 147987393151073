.SideDrawer {
  position: fixed;
  width: 40vw;
  max-width: 40%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

/* @media (min-width: 600px) {
  .SideDrawer {
    padding: 0vh 3vh;
    width: 20vw;
  }
} */


@media (min-width: 600px) {
  .SideDrawer {
    width: 20vw;
  }
}