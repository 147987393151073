.FooterItem {
  margin: 0;
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
}

.FooterItem a {
  color: black;
  height: 100%;
  padding: 16px 10px;
  border-bottom: 4px solid transparent;
  font-size: 0.8rem;
}

.FooterItem a:hover,
.FooterItem a:active,
.FooterItem a.active {
  color: black;
}

@media (min-width: 600px) {
  .FooterItem a {
    font-size: inherit;
  }
}