.list-foodcourt img {
  width: 13vh;
  height: 13vh;
  border: 1px solid #A2A2A2;
  border-radius: 1vh 1vh 1vh 1vh;
}

@media (min-width: 600px) {
  .list-foodcourt img  {
    border-radius: 2vh 2vh 2vh 2vh;
    width: 15vh;
    height: 15vh;
  }
}

@media (min-width: 900px) {
  .warning {
    font-size: 1.05rem;
    color: red;
  }
  
  .success {
    font-size: 1.05rem;
    color:green;
  }

  .list-foodcourt img  {
    border-radius: 3vh 3vh 3vh 3vh;
    width: 28vh;
    height: 28vh;
  }
}






