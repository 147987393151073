.button-edit-qty {
  border: none;
  background-color: transparent;
  /* position: absolute; */
  bottom: 0;
}

.box-shadow {
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
}

.button-counter-plus-minus {
  position: relative;
  width: 120px;
  height: 30px;
  padding: 0;
}

.button-counter-plus-minus .dec.qty {
  top: 0;
  left: 0;
  height: 30px;
}

.button-counter-plus-minus .inc.qty {
  top: 0;
  right: 0;
  height: 30px;
}

.button-counter-plus-minus .inc.qty  {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  float: inherit;
  width: 30px;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-align: center;
  color: black;
  border: none;
  background: none;
}

.button-counter-plus-minus .dec.qty {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  float: inherit;
  width: 30px;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-align: center;
  color: black;
  border: none;
  background: none;
}

.button-counter-plus-minus input.plus-minus-box {
  font-size: 14px;
  font-weight: bold;
  float: left;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: black;
  border-color: gray;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #aaaaaa;
  border-style: groove;
  background: transparent none repeat scroll 0 0;
}

.space-margin-b-10 {
  margin-bottom: 10px;
}

.space-margin-r-10 {
  margin-right: 10px;
}