.bank-box {
  border: 1px solid gray;
  border-radius: 1vh 1vh 1vh 1vh;
  padding: 2vh 0;
}

.voucher-bank {
  margin-right: 2vw;
}

.voucher-bank img {
  width: 100%;
}