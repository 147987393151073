.input-form {
  padding: 0 !important;
}

.Input {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 0.6rem;
}

.InputElement {
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  /* font: inherit; */
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.Label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.InputElement:focus {
  outline: none;
  background-color: #ccc;
}

.Invalid {
  border: 1px solid red;
  background-color: #FDA49A;
}

@media (min-width: 600px) {
  .Input {
    font-size: 1.05rem;
  }
}