.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
	vertical-align: bottom;
	background:#339966;
  animation:myfirst 2s;
  -moz-animation:myfirst 2s infinite; /* Firefox */
  -webkit-animation:myfirst 2s infinite; /* Safari and Chrome */
}

.my-float{
  margin: 0;
  -ms-transform: translate(0%, 0%);
  transform: translate(0%, 75%);
}

@keyframes myfirst {
  0% {background: #339966;}
  50% {background:#0C9;}
  0% {background: #339966;}
}

@-moz-keyframes myfirst /* Firefox */ {
  0% {background: #339966;}
  50% {background:#0C9;}
  0% {background: #339966;}
}

@-webkit-keyframes myfirst /* Safari and Chrome */ {
  0% {background: #339966;}
  50% {background:#0C9;}
  0% {background: #339966;}
}
