.widget-svg {
  width: 18px !important;
  height: 18px !important;
}

.button-cart {
  height: 2vh;
}

.button-cart-foodcourt {
  width: 80%;
}

.button-cart-list {
  z-index: 2;
  transform: translate(-50%, -20%);
  -ms-transform: translate(-50%, -20%);
}

.listing-list-header {
  top: 6vh;
  left: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 10vh;
  background-color: white;
  color: white;
  padding-right: 5%;
  padding-left: 5%;
}

.listing-list-content {
  position: relative;
  z-index: 1;
  top: 10vh;
  height: 100%;
  margin-bottom: 20vh;
}



