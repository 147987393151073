.sides {
  position: absolute;
  left:0;
  display: none;
  height: 3vh;
  width: 0.5vw;
  background-color: #FFD000;
}

.SidebarMenu {
  width: 100%;
  float: left;
  padding-left: 4vw;
}

.NavigationItem {
  margin: 3vh 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a, .div-a {
  color: #808184;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 0.8rem;
  letter-spacing: 0rem;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active .sides {
  display: block;
}

@media (min-width: 600px) {
  .NavigationItem a, .div-a {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
}